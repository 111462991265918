.section-charity-donations {
  .site-content {
    padding: 0 20px;
    @include breakpoint($medium-up) {
      padding: 0 40px;
    }
  }
}

.pg_wrapper--checkout {
  .donations-panel {
    &__header {
      font-size: get-rem(18px);
      letter-spacing: 0.01px;
      line-height: get-line-height(18, 24);
    }
  }
}
