.contact_us {
  .pg_wrapper {
    display: flex;
    flex-flow: column wrap;
    max-width: 1254px;
    padding: 0 20px;
    width: 100%;
    @include breakpoint($medium-up) {
      flex-direction: row;
      padding: 0 40px;
    }
    .adpl {
      input[type='email'],
      input[type='tel'],
      input[type='password'],
      input[type='text'],
      select,
      textarea {
        padding-top: 0;
        width: 100%;
        @include breakpoint($medium-up) {
          width: 50%;
        }
      }
      textarea {
        padding-top: 10px;
      }
      .comment_container {
        .label {
          display: none;
        }
      }
      label {
        padding-left: 0;
        &::before {
          line-height: 1;
        }
      }
    }
    .form_element,
    .address2_container,
    .qas_submit_container {
      margin-top: 20px;
    }
    .error_messages li {
      color: $color-error;
      font-size: 14px;
      line-height: 1.6;
      padding: 0 0 12px;
    }
  }
}
